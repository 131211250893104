<template>
  <ContentCard
    :img="item.img"
    :title="item.name"
    :summary="null"
    :featured="item.featured"
    :to="to ? to : (routeName ? {name: routeName, params: {id : item.id }} : null)"
    @click="hasClickListener ? $emit('click') : null" 
    />
</template>
 
<script>
import ContentCard from '@/components/generic/ContentCard.vue'

export default {
  name: "MapTemplateCard",
  components: {
    ContentCard,
  },
  props: {
    item: Object,
    to: [Object, String],
    routeName: String,
  },
  data: function() {
    return {
    };
  },
  mounted() {
  },
  methods: {
      
  },
  computed: {
    hasClickListener(){
      return this.$listeners && this.$listeners.click;
    }
  }
};
</script>

<style lang="scss">
</style>

        