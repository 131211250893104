<template>
    <v-card 
      :class="{ 'd-flex flex-column grow': true, 'card--featured': featured}" 
      @click="hasClickListener ? $emit('click') : null" 
      :to="to ? to : null"
      >
      <div v-if="featured && !mini" class="featured-badge" title="Featured">
        <v-icon color="black">fa-star</v-icon>
      </div>
      <v-img
        v-if="img"
        :src="img"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        :height="mini ? '100px' : '200px'"
      > 
        <v-card-title class="d-inline-block">{{title | trim(50)}}<v-icon v-if="!hideIndicator" color="white" small class="ms-2">fa-chevron-right</v-icon></v-card-title>
        <v-card-text v-if="!mini && summary">{{summary}}</v-card-text>
      </v-img>
      <v-sheet v-else class="d-flex flex-column justify-end " :color="$helpers.getGradientColor(0)" :height="mini ? 100 : 200">
        <v-card-title class="d-inline-block">{{title}} <v-icon v-if="!hideIndicator" color="black" small class="ml-2">fa-chevron-right</v-icon></v-card-title>
        <v-card-text v-if="!mini && summary">{{summary}}</v-card-text>
      </v-sheet>
    </v-card>    
</template>
 
<script>

export default {
  name: "ContentCard",
  components: {
  },
  props: {
    title: String,
    summary: String,
    img: String,
    featured: Boolean,
    to: Object,
    routeName: String,
    mini: Boolean,
    hideIndicator: Boolean,
  },
  data: function() {
    return {
    };
  },
  mounted() {
  },
  methods: {
      
  },
  computed: {
    hasClickListener(){
      return this.$listeners && this.$listeners.click;
    }
  }
};
</script>

<style lang="scss">
</style>

        